import React, { useState } from 'react';
import { Grid, Paper, Typography, Box } from '@mui/material';
import { LoginProvider } from '../providers/login';
import UploadIcon from '@mui/icons-material/Upload';
import OpenInBrowserIcon from '@mui/icons-material/OpenInBrowser';
import CloudSyncIcon from '@mui/icons-material/CloudSync';
import DownloadIcon from '@mui/icons-material/Download';
import { Contract } from '../models/contract';
import Alert from '../components/Alert';
import * as path from 'path-browserify';

const STATUS_DESCRIPTION:{[key:string]:string} = {
    "conflict": "Non conforme",
    "wait": "En attente de traitrement",
    "valid": "Validé"
};

function DocumentInput(props: any) {
    const doc: any = props.document;
    const contract: Contract = props.contract;
    const setFiles: Function = props.setFiles;
    const setAlert: Function = props.setAlert;
    const file: { id: number, file: File | null, error: string | null } = props.file;
    const loginProvider = new LoginProvider();
    const [state, setState] = useState<string>(!doc.state ? 'wait' : doc.state);

    const url: string | null = doc.url ? doc.url.substring(doc.url.lastIndexOf('/') + 1) : null;

    const openableExt: Array<string> = [
        "pdf"
    ];

    const iconSize:number = 26;

    async function submit(e: React.ChangeEvent<HTMLInputElement>) {
        e.preventDefault()
        const nfile = e.target.files ? e.target.files[0] : null;

        if (nfile) {
            const allowed_filetypes = doc.Document.filetypes
            const max_size = (doc.Document.maxSize || 1)

            if (nfile.size >= max_size * 1024 * 1024) {
                setAlert({
                    variant:"warning",
                    message:`La taille du fichier ne peut dépassé ${max_size} Mo.`
                });
                return;
            } else if (!((allowed_filetypes?.map((e: any) => e.label.toLowerCase()) ?? ['pdf']).includes(nfile.name.substring(nfile.name.lastIndexOf(".") + 1).toLowerCase()))) {
                setAlert({
                    variant:"warning",
                    message:`Only ${allowed_filetypes?.map((e: any) => e.label.toUpperCase()).join(', ')} supported`
                });
                return;
            }

            setFiles((files: any) => {
                return files!.map((f: any) => {
                    if (f.id === file.id) f.file = nfile;
                    return f
                })
            })
            try {
                await loginProvider.sendDocument({ id: doc.DocumentId, contractId: props.contract.id, file: nfile!, error: null })
                .then(r => {
                    if (!r) {
                        setAlert({
                            variant:"error",
                            message:"Échec de la communication avec les serveurs"
                        });
                    } else if (r.data) {
                        if (r.data.alert) {
                            setAlert(r.data.alert);
                        }
                    }
                    props.reload()
                });
                setState('wait')
            } catch (err) {
                setAlert({
                    variant:"error",
                    message:`Erreur: ${err}`
                });
            }
        }
    }

    return (
        <div>
            <div className={`status-color status-${state}`} title={STATUS_DESCRIPTION[state]}></div>
            <Typography noWrap sx={{ fontSize:"medium" }}>
                {doc.Document.label}
            </Typography>
            <div className='sendFileBlock'>
                {!file?.file && !url ? <>
                    <UploadIcon titleAccess="Importer" sx={{ color: '#9f9f9f', height: iconSize, width: iconSize }} onClick={() => (document.querySelector(`#input-${doc.id}`) as HTMLElement)?.click()}/>
                    <Typography sx={{ fontSize:"small" }}>
                        Choisir un fichier
                        <span style={{ fontSize:"x-small", color:"#666", display:'block' }}>
                            Supporté(s) : {doc.Document.filetypes?.map((e: any) => e.label.toUpperCase()).join(', ') || 'PDF'}
                        </span>
                    </Typography>
                </>
                    : <>
                        {/* Si le fichier n'est pas conforme, alors nous
                        affichons le bouton d'édition. */}
                        {contract.state !== 'compliant' && state !== 'valid' &&
                            <CloudSyncIcon
                                sx={{
                                    color: '#9f9f9f',
                                    height: iconSize,
                                    width: iconSize
                                }}
                                titleAccess="Changer le fichier"
                                onClick={() => (document.querySelector(`#input-${doc.id}`) as HTMLElement)?.click()}/>
                        }

                        {/* Si le fichier peut être visionné. */}
                        {-1 < openableExt.indexOf(doc.url.split('.').pop()) ?
                            <OpenInBrowserIcon
                                sx={{ color: '#9f9f9f', height: iconSize, width: iconSize }}
                                titleAccess="Ouvrir"
                                onClick={async e => {
                                    e.stopPropagation();

                                    const url = window.URL.createObjectURL(await loginProvider.downloadDocument(contract.id as string, doc.url)),
                                        link = document.createElement("a");

                                    link.href = url;
                                    link.setAttribute("target", doc.filename);
                                    link.setAttribute("rel", "noopener noreferrer");
                                    link.click();
                                }}
                            />
                        : <></>
                        }

                        {/* Téléchargement du fichier. */}
                        <DownloadIcon
                             sx={{ color: '#9f9f9f', height: iconSize, width: iconSize }}
                             titleAccess="Télécharger"
                             onClick={async e => {
                                e.stopPropagation();

                                const url = window.URL.createObjectURL(await loginProvider.downloadDocument(contract.id as string, doc.url)),
                                      link = document.createElement("a");


                                link.href = url;
                                link.setAttribute("download", doc.filename);
                                link.click();
                             }}
                         />

                        <Typography sx={{ fontSize:"small" }}>
                            {doc?.filename}
                        </Typography>
                    </>
                }

                {contract.state !== 'compliant' && state !== 'valid' &&
                    <input
                        id={`input-${doc.id}`}
                        type="file"
                        name="file"
                        style={{ display:"none" }}
                        accept={(doc.Document.filetypes as Array<{ label: string }>)?.map((e) => "." + e.label.toLowerCase()).join(',') ?? "application/pdf"}
                        onChange={(e) => submit(e)}
                    />
                }
            </div>

            { doc.NonComplianceReason && 
                <Box mt={1}>
                    <Typography sx={{ fontSize:"x-small", color:"#666" }}>
                        Raison de non conformitée
                    </Typography>
                    <Typography sx={{ fontSize:"small" }}>{doc.NonComplianceReason.label}</Typography>
                </Box>
            }
        </div>
    );
}

export default DocumentInput;
