import axios from 'axios'
import { Contract } from '../models/contract'

const ENDPOINT = process.env.REACT_APP_API_URL;

export class LoginProvider {
    token!: string
    username!: string[]
    data!: Contract[]

    constructor() {
        this.load();
    }

    save() {
        localStorage.setItem('token', this.token)
        localStorage.setItem('username', this.username.join('|'))
    }

    load() {
        const token = localStorage.getItem('token')
        const username = localStorage.getItem('username')
        if (token) this.token = token
        if (username) this.username = username.split('|')

    }

    getToken() {
        this.load()
        return this.token
    }

    set(token: string) {
        this.token = token
        this.username = this.parseJwt().username.split('|');
        this.save()
    }

    logOut() {
        localStorage.clear()
    }

    async getContracts(): Promise<Contract[]> {
        try {
            this.load()
            let res = await axios.get(ENDPOINT + '/client/auth/contracts', { headers: { Authorization: this.token } })
            this.data = res.data
            return res.data;
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return []
            } else {
                console.error(error)
                return []
            }
        }
    }

    async sendDocument(file: { id: number, contractId: string, file: File | null, error: string | null }) {
        try {
            const fileForm = new FormData()
            fileForm.append('file', file.file!)
            fileForm.append('filename', file.file?.name!)

            return await axios.post(
                ENDPOINT + `/client/auth/document/${file.contractId}/${file.id}`,
                fileForm,
                { headers: { Authorization: this.token } }
            )
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return error.response?.data.message
            } else {
                console.error(error)
                return 'Unknown error'
            }
        }
    }

    async getCode(email: string) {
        try {
            await axios.post(ENDPOINT + '/client/get-code', { 'email': email })
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return error.response?.data.message
            } else {
                console.error(error)
                return 'Unknown error'
            }
        }
    }

    async sendCode(email: string, code: string) {
        //this.set('eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOiIxNzIwMDg2NDk0IiwidXNlciI6ImhlbGxvd29ybGQifQ.H37MRtM6Gp1rBuhMfumLywOzyDg7VJz0sKu5b2J78Wo');
        try {
            const res = await axios.post(ENDPOINT + '/client/login', { 'email': email, 'otpCode': code })
            if (res.data.token !== undefined) {
                this.set(res.data.token)
            } else {
                return "Code incorrect"
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                return error.response?.data.message
            } else {
                console.error(error)
                return 'Unknown error'
            }
        }
    }


    async downloadDocument(contractId: string, id: string) {
        const result = await axios({
            method: 'get',
            headers: { Authorization: `${this.token}` },
            url: ENDPOINT + `/client/auth/document/${contractId}/${id}`,
            responseType: 'blob',
        })
            .then(res => {
                if (res.data) {
                    return res.data;
                }
            })
            .catch(err => {
                return { errorStatus: err.response?.status };
            });

        return result;
    };

    async downloadContract(contractId: string) {
        return await axios({
            method: 'get',
            headers: { Authorization: `${this.token}` },
            url: ENDPOINT + `/public/contracts/${contractId}/${contractId}.pdf`,
            responseType: 'blob',
        })
            .then(res => {
                if (res.data) {
                    return res.data;
                }
            })
            .catch(err => {
                return { errorStatus: err.response?.status };
            });
    }

    checkTokenValidity() {
        if (!this.token) return false
        const data = this.parseJwt()
        if (!data?.exp) return false
        return data.exp * 1000 > Date.now()
    }

    parseJwt() {
        var base64Url = this.token.split('.')[1]
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
        var jsonPayload = decodeURIComponent(
            window
                .atob(base64)
                .split('')
                .map(function (c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
                })
                .join('')
        )

        return JSON.parse(jsonPayload)
    }
}
